import { create } from "zustand";
import { ICourse } from "../screens/list";

export type ICourseStore = {
  courses: ICourse[];
  errors: string[];
  setError: (error: string) => void;
  setCourses: (bucket: ICourse[]) => void;
  getCourse: (id: string) => ICourse;
};

const useCourseStore = create<ICourseStore>((set, get) => ({
  courses: [],
  errors: [],
  setError: (error: string) =>set((state: ICourseStore) => ({ errors: [...state.errors, error] })),
  setCourses: (courses: ICourse[]) => set(() => ({ courses })),
  getCourse: (id) => get().courses.filter((course) => course.id == id)[0],
}));

export default useCourseStore;